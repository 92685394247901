import Backbone from 'backbone';

export default Backbone.Model.extend({
  build(model, opts = {}) {
    const models = model.get('components');

    if (opts.exportWrapper) {
      return model.toHTML({
        ...(opts.wrapperIsBody && { tag: 'body' }),
        ...opts
      });
    }

    return this.buildModels(models, opts);
  },

  buildModels(models, opts = {}) {
    let code = '';
    models.each(model => {
      code += model.toHTML(opts);
    });
    return code;
  }
});
