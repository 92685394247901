import Backbone from 'backbone';

export default Backbone.View.extend({
  template({ pfx, ppfx, content, title }) {
    return `<div class="${pfx}dialog ${ppfx}one-bg ${ppfx}two-color">
      <div class="${pfx}header">
        <div class="${pfx}title">${title}</div>
        <div class="${pfx}btn-close" data-close-modal>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fill-rule="evenodd">
              <path fill="#FFF" d="M0 0H20V20H0z" opacity=".01"/>
              <path fill="#AEAEB2" d="M12.121 10l5.688 5.687c.232.232.367.453.437.683.07.23.07.465 0 .695-.07.23-.205.45-.437.683l-.061.06c-.232.233-.453.368-.683.438-.23.07-.465.07-.695 0-.23-.07-.45-.205-.683-.437L10 12.12l-5.687 5.69c-.232.232-.453.367-.683.437-.23.07-.465.07-.695 0-.23-.07-.45-.205-.683-.437l-.06-.061c-.233-.232-.368-.453-.438-.683-.07-.23-.07-.465 0-.695.07-.23.205-.45.437-.683L7.88 10 2.19 4.313c-.232-.232-.367-.453-.437-.683-.07-.23-.07-.465 0-.695.07-.23.205-.45.437-.683l.061-.06c.232-.233.453-.368.683-.438.23-.07.465-.07.695 0 .23.07.45.205.683.437L10 7.88l5.687-5.688c.232-.232.453-.367.683-.437.23-.07.465-.07.695 0 .23.07.45.205.683.437l.06.061c.233.232.368.453.438.683.07.23.07.465 0 .695-.07.23-.205.45-.437.683L12.121 10z"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="${pfx}content">
        <div id="${pfx}c">${content}</div>
        <div style="clear:both"></div>
      </div>
    </div>
    <div class="${pfx}collector" style="display: none"></div>`;
  },

  events: {
    click: 'onClick',
    'click [data-close-modal]': 'hide'
  },

  initialize(o) {
    const model = this.model;
    const config = o.config || {};
    const pfx = config.stylePrefix || '';
    this.config = config;
    this.pfx = pfx;
    this.ppfx = config.pStylePrefix || '';
    this.listenTo(model, 'change:open', this.updateOpen);
    this.listenTo(model, 'change:title', this.updateTitle);
    this.listenTo(model, 'change:content', this.updateContent);
  },

  onClick(e) {
    const bkd = this.config.backdrop;
    const backdropProp = this.model.get('backdrop'); // REI
    bkd && backdropProp && e.target === this.el && this.hide(); // REI
  },

  /**
   * Returns collector element
   * @return {HTMLElement}
   * @private
   */
  getCollector() {
    if (!this.$collector)
      this.$collector = this.$el.find('.' + this.pfx + 'collector');
    return this.$collector;
  },

  /**
   * Returns content element
   * @return {HTMLElement}
   * @private
   */
  getContent() {
    const pfx = this.pfx;

    if (!this.$content) {
      this.$content = this.$el.find(`.${pfx}content #${pfx}c`);
    }

    return this.$content;
  },

  /**
   * Returns title element
   * @return {HTMLElement}
   * @private
   */
  getTitle() {
    if (!this.$title) this.$title = this.$el.find('.' + this.pfx + 'title');
    return this.$title.get(0);
  },

  /**
   * Update content
   * @private
   * */
  updateContent() {
    var content = this.getContent();
    const children = content.children();
    const coll = this.getCollector();
    const body = this.model.get('content');
    children.length && coll.append(children);
    content.empty().append(body);
  },

  /**
   * Update title
   * @private
   * */
  updateTitle() {
    var title = this.getTitle();
    if (title) title.innerHTML = this.model.get('title');
  },

  /**
   * Update open
   * @private
   * */
  updateOpen() {
    this.el.style.display = this.model.get('open') ? '' : 'none';
  },

  /**
   * Hide modal
   * @private
   * */
  hide() {
    this.model.set('open', 0);
  },

  /**
   * Show modal
   * @private
   * */
  show() {
    this.model.set('open', 1);
  },

  render() {
    const el = this.$el;
    const pfx = this.pfx;
    const ppfx = this.ppfx;
    const obj = this.model.toJSON();
    obj.pfx = this.pfx;
    obj.ppfx = this.ppfx;
    el.html(this.template(obj));
    el.attr('class', `${pfx}container`);
    this.updateOpen();
    return this;
  }
});
