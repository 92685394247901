import { template } from 'underscore';
import Backbone from 'backbone';

let in_magic_triangle = false;
let previous_X = 0;
let MAGIC_A, MAGIC_B, MAGIC_C;

const area = function(A, B, C) {
  return Math.abs(
    (A.x * (B.y - C.y) + B.x * (C.y - A.y) + C.x * (A.y - B.y)) / 2
  );
};

const pointInTriangle = function(D, A, B, C) {
  var ABD = area(A, B, D);
  var BDC = area(B, D, C);
  var CAD = area(C, A, D);
  var ABC = area(A, B, C);
  if (ABC == ABD + BDC + CAD) {
    return true;
  }
  return false;
};

export default Backbone.View.extend({
  template: template(`
    <div class="<%= pfx %>title">
      <i class="<%= pfx %>caret-icon"></i>
      <%= label %>
    </div>
    <div class="<%= pfx %>blocks-c"></div>
  `),

  events: {},

  initialize(o = {}, config = {}) {
    this.config = config;
    const pfx = config.pStylePrefix || '';
    this.em = config.em;
    this.pfx = pfx;
    this.activeClass = `${pfx}open`;
    this.className = `${pfx}block-category`;
    this.events[`mousemove .${pfx}title`] = 'mouseMove';
    this.listenTo(this.model, 'change:open', this.updateVisibility);
    this.delegateEvents();
  },

  mouseMove(e) {
    const $title = this.$el.find(`.${this.pfx}title`);
    const cataClass = `.${this.pfx}blocks-c`;

    if (!in_magic_triangle) {
      let model = this.model;
      if (!model.get('open')) this.closeAll();
      model.set('open', true);

      MAGIC_A = { x: e.pageX, y: e.pageY };
      MAGIC_B = {
        x: $title.siblings(cataClass).offset().left,
        y: $title.siblings(cataClass).offset().top
      };
      MAGIC_C = {
        x: $title.siblings(cataClass).offset().left,
        y:
          $title.siblings(cataClass).offset().top +
          $title.siblings(cataClass).outerHeight()
      };
      in_magic_triangle = true;
    } else {
      const D = { x: e.pageX, y: e.pageY };
      if (
        e.pageX < previous_X ||
        !pointInTriangle(D, MAGIC_A, MAGIC_B, MAGIC_C)
      ) {
        in_magic_triangle = false;
      }
      previous_X = e.pageX;
    }
  },

  updateVisibility() {
    if (this.model.get('open')) this.open();
    else this.close();
  },

  open() {
    this.el.className = `${this.className} ${this.activeClass}`;
    this.getBlocksEl().style.display = '';
    this.getBlocksEl().style.zIndex = '2';
  },

  close() {
    this.el.className = this.className;
    this.getBlocksEl().style.display = 'none';
    this.getBlocksEl().style.zIndex = 'auto';
  },

  closeAll() {
    this.model.collection.models.forEach(item => {
      item.set('open', false);
    });
  },

  getBlocksEl() {
    if (!this.blocksEl) {
      this.blocksEl = this.el.querySelector('.' + this.pfx + 'blocks-c');
    }

    return this.blocksEl;
  },

  append(el) {
    this.getBlocksEl().appendChild(el);
  },

  render() {
    const { em, el, $el, model } = this;
    const label =
      em.t(`blockManager.categories.${model.id}`) || model.get('label');
    el.innerHTML = this.template({
      pfx: this.pfx,
      label
    });
    el.className = this.className;
    $el.css({ order: model.get('order') });
    this.updateVisibility();

    return this;
  }
});
