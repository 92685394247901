import { isUndefined, isString } from 'underscore';
import { getModel } from 'utils/mixins';
import Backbone from 'backbone';
import ComponentView from 'dom_components/view/ComponentView';

const inputProp = 'contentEditable';
const $ = Backbone.$;
let ItemsView;

export default Backbone.View.extend({
  events: {
    'mousedown [data-toggle-move]': 'startSort',
    'touchstart [data-toggle-move]': 'startSort',
    'click [data-toggle-visible]': 'toggleVisibility',
    // 'click [data-toggle-select]': 'handleSelect', // REI
    // 'mouseover [data-toggle-select]': 'handleHover', // REI
    // 'click [data-toggle-open]': 'toggleOpening', // REI
    // 'dblclick [data-name]': 'handleEdit', // REI
    // 'focusout [data-name]': 'handleEditEnd' // REI
    'click [data-trash]': 'handleDelete',
    'click [data-move-up]': 'handleMoveUp',
    'click [data-move-down]': 'handleMoveDown'
  },

  template(model) {
    const { pfx, ppfx, config, clsNoEdit } = this;
    const { hidable } = config;
    const count = this.countChildren(model);
    const addClass = !count ? this.clsNoChild : '';
    const clsTitle = `${this.clsTitle} ${addClass}`;
    const clsTitleC = `${this.clsTitleC} ${ppfx}one-bg`;
    const clsCaret = `${this.clsCaret} fa fa-chevron-right`;
    const clsInput = `${this.inputNameCls} ${clsNoEdit} ${ppfx}no-app`;
    const level = this.level + 1;
    const gut = `${30 + level * 10}px`;
    const name = model.getName();
    const icon = model.getIcon();
    const clsBase = `${pfx}layer`;
    const clsTrash = `${pfx}layer-trash`;
    const clsMoveUp = `${pfx}layer-move-up`;
    const clsMoveDown = `${pfx}layer-move-down`;

    // REI
    return `
      ${
        hidable
          ? `<i class="${pfx}layer-vis fa fa-eye ${
              this.isVisible() ? '' : 'fa-eye-slash'
            }" data-toggle-visible></i>`
          : ''
      }
      <div class="${clsTitleC}">
        <div class="${clsTitle}" data-toggle-select data-toggle-move>
          <div class="${pfx}layer-title-inn">
            <i class="${clsCaret}" data-toggle-open></i>
            ${icon ? `<span class="${clsBase}__icon">${icon}</span>` : ''}
            <span class="${clsInput}" data-name>${name}</span>
          </div>
        </div>
      </div>
      <div class="${this.clsCount}" data-count>${count || ''}</div>
      <div class="${clsTrash}" data-trash>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fill-rule="evenodd">
              <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01"/>
              <path fill="#8E8E93" fill-rule="nonzero" d="M11.295 16c1.055 0 1.746-.658 1.8-1.71l.493-10.379h.843c.31 0 .569-.257.569-.567 0-.318-.258-.575-.57-.575h-3.256V1.732C11.174.681 10.46 0 9.314 0H6.67c-1.146 0-1.86.68-1.86 1.732V2.77H1.57c-.31 0-.569.257-.569.575 0 .31.258.567.57.567h.842l.494 10.387C2.95 15.349 3.642 16 4.705 16h6.59zM9.966 2.769h-3.94V1.8c0-.4.296-.688.729-.688h2.483c.432 0 .728.288.728.688v.969zm1.2 12.089h-6.34c-.41 0-.713-.303-.736-.727l-.493-10.22h8.776l-.47 10.22c-.016.432-.312.727-.737.727zm-1.04-1.226c.243 0 .425-.204.44-.484l.213-7.452c.007-.272-.182-.491-.433-.491-.235 0-.433.22-.44.484l-.213 7.451c-.008.273.175.492.433.492zm-4.252 0c.25 0 .44-.22.433-.492L6.094 5.69c-.007-.265-.212-.484-.44-.484-.258 0-.44.212-.433.491l.213 7.452c.007.28.19.484.44.484zm2.126 0c.243 0 .448-.22.448-.492V5.696c0-.272-.205-.491-.448-.491-.243 0-.448.22-.448.491v7.444c0 .273.205.492.448.492z"/>
          </g>
        </svg>
      </div>
      <div class="${clsMoveUp}" data-move-up>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fill-rule="evenodd">
            <path fill="#FFF" d="M0 0H15.965V15.974H0z" opacity=".01" transform="rotate(-180 8 8)"/>
            <path fill="#8E8E93" fill-rule="nonzero" d="M7.983 0C8.67 0 9.23.558 9.23 1.247v8.737H6.735V1.247C6.735.558 7.294 0 7.983 0z" transform="rotate(-180 8 8)"/>
            <path fill="#8E8E93" fill-rule="nonzero" d="M7.65 15.641l-4.853-4.855c-.183-.184-.183-.481 0-.665.089-.088.208-.137.333-.137h9.705c.26 0 .47.21.47.47 0 .124-.05.244-.137.332L8.315 15.64c-.184.184-.481.184-.665 0z" transform="rotate(-180 8 8)"/>
          </g>
        </svg>
      </div>
      <div class="${clsMoveDown}" data-move-down>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fill-rule="evenodd">
              <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01"/>
              <path fill="#8E8E93" fill-rule="nonzero" d="M8 0c.69 0 1.25.56 1.25 1.25V10h-2.5V1.25C6.75.56 7.31 0 8 0zM7.668 15.668l-4.866-4.866c-.183-.183-.183-.48 0-.664.088-.088.208-.138.333-.138h9.73c.26 0 .47.21.47.47 0 .125-.05.244-.137.332l-4.866 4.866c-.183.183-.48.183-.664 0z"/>
          </g>
        </svg>
      </div>
      <div class="${this.clsMove}" data-toggle-move>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fill-rule="evenodd">
            <path fill="#FFF" d="M0 0H16V16H0z" opacity=".01"/>
            <path fill="#8E8E93" fill-rule="nonzero" d="M13.52 10.011c.268 0 .48.216.48.489s-.212.489-.48.489H2.48c-.268 0-.48-.216-.48-.489s.212-.489.48-.489zm0-2.5c.268 0 .48.216.48.495 0 .267-.212.483-.48.483H2.48c-.268 0-.48-.216-.48-.483 0-.279.212-.495.48-.495zm0-2.5c.268 0 .48.222.48.489s-.212.489-.48.489H2.48c-.268 0-.48-.222-.48-.489s.212-.489.48-.489z"/>
          </g>
        </svg>
      </div>
      <div class="${this.clsChildren}"></div>`;

    // GRAPESJS
    // return `
    // ${
    //   hidable
    //     ? `<i class="${pfx}layer-vis fa fa-eye ${
    //         this.isVisible() ? '' : 'fa-eye-slash'
    //       }" data-toggle-visible></i>`
    //     : ''
    // }
    // <div class="${clsTitleC}">
    //   <div class="${clsTitle}" style="padding-left: ${gut}" data-toggle-select>
    //     <div class="${pfx}layer-title-inn">
    //       <i class="${clsCaret}" data-toggle-open></i>
    //       ${icon ? `<span class="${clsBase}__icon">${icon}</span>` : ''}
    //       <span class="${clsInput}" data-name>${name}</span>
    //     </div>
    //   </div>
    // </div>
    // <div class="${this.clsCount}" data-count>${count || ''}</div>
    // <div class="${this.clsMove}" data-toggle-move>
    //   <i class="fa fa-arrows"></i>
    // </div>
    // <div class="${this.clsChildren}"></div>`;
  },

  initialize(o = {}) {
    this.opt = o;
    this.level = o.level;
    this.config = o.config;
    this.em = o.config.em;
    this.ppfx = this.em.get('Config').stylePrefix;
    this.sorter = o.sorter || '';
    this.pfx = this.config.stylePrefix;
    this.parentView = o.parentView;
    const pfx = this.pfx;
    const ppfx = this.ppfx;
    const model = this.model;
    const components = model.get('components');
    const type = model.get('type') || 'default';
    model.set('open', false);
    this.listenTo(components, 'remove add reset', this.checkChildren);
    this.listenTo(model, 'change:status', this.updateStatus);
    this.listenTo(model, 'change:open', this.updateOpening);
    this.listenTo(model, 'change:layerable', this.updateLayerable);
    this.listenTo(model, 'change:style:display', this.updateVisibility);
    this.className = `${pfx}layer ${pfx}layer__t-${type} no-select ${ppfx}two-color`;
    this.inputNameCls = `${ppfx}layer-name`;
    this.clsTitleC = `${pfx}layer-title-c`;
    this.clsTitle = `${pfx}layer-title`;
    this.clsCaret = `${pfx}layer-caret`;
    this.clsCount = `${pfx}layer-count`;
    this.clsMove = `${pfx}layer-move`;
    this.clsChildren = `${pfx}layer-children`;
    this.clsNoChild = `${pfx}layer-no-chld`;
    this.clsEdit = `${this.inputNameCls}--edit`;
    this.clsNoEdit = `${this.inputNameCls}--no-edit`;
    this.$el.data('model', model);
    this.$el.data('collection', components);
    model.viewLayer = this;
  },

  getVisibilityEl() {
    if (!this.eyeEl) {
      this.eyeEl = this.$el.children(`.${this.pfx}layer-vis`);
    }

    return this.eyeEl;
  },

  updateVisibility() {
    const pfx = this.pfx;
    const model = this.model;
    const hClass = `${pfx}layer-hidden`;
    const hideIcon = 'fa-eye-slash';
    const hidden = model.getStyle().display == 'none';
    const method = hidden ? 'addClass' : 'removeClass';
    this.$el[method](hClass);
    this.getVisibilityEl()[method](hideIcon);
  },

  /**
   * Toggle visibility
   * @param	Event
   *
   * @return 	void
   * */
  toggleVisibility(e) {
    e && e.stopPropagation();
    const model = this.model;
    const style = model.getStyle();
    const hidden = style.display == 'none';

    if (hidden) {
      delete style.display;
    } else {
      style.display = 'none';
    }

    model.setStyle(style);
  },

  /**
   * Handle the edit of the component name
   */
  handleEdit(e) {
    e && e.stopPropagation();
    const { em, $el, clsNoEdit, clsEdit } = this;
    const inputEl = this.getInputName();
    inputEl[inputProp] = true;
    inputEl.focus();
    em && em.setEditing(1);
    $el
      .find(`.${this.inputNameCls}`)
      .removeClass(clsNoEdit)
      .addClass(clsEdit);
  },

  /**
   * Handle with the end of editing of the component name
   */
  handleEditEnd(e) {
    e && e.stopPropagation();
    const { em, $el, clsNoEdit, clsEdit } = this;
    const inputEl = this.getInputName();
    const name = inputEl.textContent;
    inputEl.scrollLeft = 0;
    inputEl[inputProp] = false;
    this.model.set({ 'custom-name': name });
    em && em.setEditing(0);
    $el
      .find(`.${this.inputNameCls}`)
      .addClass(clsNoEdit)
      .removeClass(clsEdit);
  },

  /**
   * Get the input containing the name of the component
   * @return {HTMLElement}
   */
  getInputName() {
    if (!this.inputName) {
      this.inputName = this.el.querySelector(`.${this.inputNameCls}`);
    }
    return this.inputName;
  },

  /**
   * Update item opening
   *
   * @return void
   * */
  updateOpening(...args) {
    var opened = this.opt.opened || {};
    var model = this.model;
    const chvDown = 'fa-chevron-down';

    if (model.get('open')) {
      this.$el.addClass('open');
      this.getCaret().addClass(chvDown);
      opened[model.cid] = model;
    } else {
      this.$el.removeClass('open');
      this.getCaret().removeClass(chvDown);
      delete opened[model.cid];
    }
  },

  /**
   * Toggle item opening
   * @param {Object}	e
   *
   * @return void
   * */
  toggleOpening(e) {
    e.stopPropagation();

    if (!this.model.get('components').length) return;

    this.model.set('open', !this.model.get('open'));
  },

  /**
   * Handle component selection
   */
  handleSelect(e) {
    e.stopPropagation();
    const { em, config } = this;

    if (em) {
      const model = this.model;
      em.setSelected(model, { fromLayers: 1 });
      const scroll = config.scrollCanvas;
      scroll && em.get('Canvas').scrollTo(model, scroll);
    }
  },

  /**
   * Handle component selection
   */
  handleHover(e) {
    e.stopPropagation();
    const { em, config, model } = this;
    em && config.showHover && em.setHovered(model, { fromLayers: 1 });
  },

  /**
   * REI: Handle delete component
   * @param {MouseEvent} e
   */
  handleDelete(e) {
    e.stopPropagation();
    const { em, model } = this;
    em.get('Editor').runCommand('core:component-delete', { component: model });
  },

  /**
   * REI: Handle move up component
   * Notice: `tlb-move-up` is defined on plugin
   * @param {MouseEvent} e
   */
  handleMoveUp(e) {
    e.stopPropagation();
    const { em, model } = this;
    em.get('Editor').runCommand('tlb-move-up', { component: model });
  },

  /**
   * REI: Handle move down component
   * Notice: `tlb-move-down` is defined on plugin
   * @param {MouseEvent} e
   */
  handleMoveDown(e) {
    e.stopPropagation();
    const { em, model } = this;
    em.get('Editor').runCommand('tlb-move-down', { component: model });
  },

  /**
   * Delegate to sorter
   * @param	Event
   * */
  startSort(e) {
    e.stopPropagation();
    const sorter = this.sorter;
    // Right or middel click
    if (e.button && e.button !== 0) return;
    sorter && sorter.startSort(e.target);
  },

  /**
   * Freeze item
   * @return	void
   * */
  freeze() {
    this.$el.addClass(this.pfx + 'opac50');
    this.model.set('open', 0);
  },

  /**
   * Unfreeze item
   * @return	void
   * */
  unfreeze() {
    this.$el.removeClass(this.pfx + 'opac50');
  },

  /**
   * Update item on status change
   * @param	Event
   * */
  updateStatus(e) {
    ComponentView.prototype.updateStatus.apply(this, [
      {
        avoidHover: !this.config.highlightHover
      }
    ]);
  },

  /**
   * Check if component is visible
   *
   * @return bool
   * */
  isVisible() {
    var css = this.model.get('style'),
      pr = css.display;
    if (pr && pr == 'none') return;
    return 1;
  },

  /**
   * Update item aspect after children changes
   *
   * @return void
   * */
  checkChildren() {
    const { model, clsNoChild } = this;
    const count = this.countChildren(model);
    const title = this.$el
      .children(`.${this.clsTitleC}`)
      .children(`.${this.clsTitle}`);
    let { cnt } = this;

    if (!cnt) {
      cnt = this.$el.children('[data-count]').get(0);
      this.cnt = cnt;
    }

    title[count ? 'removeClass' : 'addClass'](clsNoChild);
    if (cnt) cnt.innerHTML = count || '';
    !count && model.set('open', 0);
  },

  /**
   * Count children inside model
   * @param  {Object} model
   * @return {number}
   * @private
   */
  countChildren(model) {
    var count = 0;
    model.get('components').each(function(m) {
      var isCountable = this.opt.isCountable;
      var hide = this.config.hideTextnode;
      if (isCountable && !isCountable(m, hide)) return;
      count++;
    }, this);
    return count;
  },

  getCaret() {
    if (!this.caret || !this.caret.length) {
      const pfx = this.pfx;
      this.caret = this.$el
        .children(`.${this.clsTitleC}`)
        .find(`.${this.clsCaret}`);
    }

    return this.caret;
  },

  setRoot(el) {
    el = isString(el) ? this.em.getWrapper().find(el)[0] : el;
    const model = getModel(el, $);
    if (!model) return;
    this.stopListening();
    this.model = model;
    this.initialize(this.opt);
    this.render();
  },

  updateLayerable() {
    const { parentView } = this;
    const toRerender = parentView || this;
    toRerender.render();
  },

  render() {
    const { model, config, pfx, ppfx, opt } = this;
    const { isCountable } = opt;
    const hidden = isCountable && !isCountable(model, config.hideTextnode);
    const vis = this.isVisible();
    const el = this.$el.empty();
    const level = this.level + 1;

    if (isUndefined(ItemsView)) {
      ItemsView = require('./ItemsView').default;
    }

    const children = new ItemsView({
      collection: model.get('components'),
      config: this.config,
      sorter: this.sorter,
      opened: this.opt.opened,
      parentView: this,
      parent: model,
      level
    }).render().$el;

    if (!this.config.showWrapper && level === 1) {
      el.append(children);
    } else {
      el.html(this.template(model));
      el.find(`.${this.clsChildren}`).append(children);
    }

    if (!model.get('draggable') || !this.config.sortable) {
      el.children(`.${this.clsMove}`).remove();
    }

    !vis && (this.className += ` ${pfx}hide`);
    hidden && (this.className += ` ${ppfx}hidden`);
    el.attr('class', this.className);
    this.updateOpening();
    this.updateStatus();
    this.updateVisibility();
    return this;
  }
});
