// We also use these keys in grapesjs plugin
const StorageKeys = {
  LANDING_PAGE_ID: 'landing_page_id',
  LANDING_PAGE_CONFIG: 'landing_page_config',
  LANDING_PAGE_VERSION: 'landing_page_version',
  LANDING_PAGE_DATA: 'landing_page_data',
  VERSION_ID: 'version_id',
  MODE_PARAM: 'mode',
  USER_TOKEN: 'user_token',
  ANONYMOUS_TOKEN: 'anonymous_token'
};

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    )
  );
console.log('getCookie', name, matches)  
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * Modify key based on NODE_ENV.
 * NOTES: Do not use this function directly. It should be used only by getItem, setItem and removeItem.
 * @param {string} name Storage key
 */
function modifyKeyBasedOnEnv(name) {
  console.log('process.env.NODE_ENV', process.env.NODE_ENV, name)
  if (process.env.NODE_ENV !== 'production') {
    return `${name}_test`;
  }

  return name;
}

function getItem(name) {
  if (name === StorageKeys.USER_TOKEN || name === StorageKeys.ANONYMOUS_TOKEN) {
    return getCookie(modifyKeyBasedOnEnv(name));
  }

  return sessionStorage.getItem(modifyKeyBasedOnEnv(name));
}

function setItem(name, value) {
  if (name === StorageKeys.USER_TOKEN || name === StorageKeys.ANONYMOUS_TOKEN) {
    const expiredDate = new Date();
    expiredDate.setMonth(expiredDate.getMonth() + 1);
    document.cookie = `${modifyKeyBasedOnEnv(
      name
    )}=${value};expires=${expiredDate};domain=localhost;path=/`;
    return;
  }

  return sessionStorage.setItem(modifyKeyBasedOnEnv(name), value);
}

function removeItem(name) {
  if (name === StorageKeys.USER_TOKEN || name === StorageKeys.ANONYMOUS_TOKEN) {
    document.cookie = `name=${modifyKeyBasedOnEnv(
      name
    )};path=/;domain=localhost;expires=${new Date(0).toUTCString()}`;
    return;
  }

  return sessionStorage.removeItem(modifyKeyBasedOnEnv(name));
}

export const getUrlParams = name => {
  return new URLSearchParams(document.location.search).get(name);
};

export const getLandingpageId = () => {
  return getItem(StorageKeys.LANDING_PAGE_ID);
};

export const getUserToken = () => {
  return getItem(StorageKeys.USER_TOKEN);
};
