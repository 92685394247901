/**
 * Collect all colors that are used as background color or text color in the builder iframe.
 * This value is set every time a color picker is opened.
 * the return value is array of color (text that is returned by getComputedStyle()).
 *
 * Because the expected format CKEditor (used for text color) wants and InputColor (used for background color) wants are different,
 * it returns an object that contains necessary format.
 */

export function collectAllColors() {
  const iframe = document.querySelector('iframe');
  if (!iframe || !iframe.contentDocument) {
    return [];
  }
  const allElements = iframe.contentDocument.getElementsByTagName('*');
  const colors = new Set();
  Array.from(allElements).forEach(el => {
    const style = getComputedStyle(el);
    colors.add(style.backgroundColor);
    colors.add(style.color);
  });
  return removeDuplication(
    Array.from(colors)
      .map(parseColors)
      .filter(e => e)
  ); // remove null
}

function removeDuplication(colors) {
  const set = new Set();
  const dedup = [];
  colors.forEach(c => {
    if (!set.has(c.rgb)) {
      dedup.push(c);
    }
    set.add(c.rgb);
  });
  return dedup;
}

function parseColors(colorString) {
  const rgb = colorString.match(/rgb\((\d+), (\d+), (\d+)\)/);
  const rgba = colorString.match(/rgba\((\d+), (\d+), (\d+), (\d+)\)/);
  const match = rgb || rgba;
  if (!match) {
    return null;
  }
  const [r, g, b] = [match[1], match[2], match[3]].map(s => Number(s));
  const hex = [toHex(r), toHex(g), toHex(b)].join('');

  return {
    r,
    g,
    b,
    hex,
    rgb: toRgb(r, g, b)
  };
}

const CHARS = '0123456789abcdef'.split('');
function toHex(number) {
  if (number < 0 || number > 255) {
    return '';
  }
  return CHARS[Math.floor(number / 16)] + CHARS[number % 16];
}

function toRgb(r, g, b) {
  return `rgb(${r}, ${g}, ${b})`;
}
